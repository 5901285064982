import React, { useState } from "react";
import emailjs from "emailjs-com";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [formStatus, setFormStatus] = useState({
    submitted: false,
    error: false,
    success: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailJsID = {
      SERVICE_ID: "service_fjcihb7",
      TEMPLATE_ID: "template_3atj13f",
      USER_ID: "ugERV3X_0U5i-03LO",
      autoReplyTemplateID: "template_v26xo12",
    };

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        emailJsID.SERVICE_ID,
        emailJsID.TEMPLATE_ID,
        templateParams,
        emailJsID.USER_ID
      )
      .then((response) => {
        console.log("Email successfully sent!", response.status, response.text);
        alert("Message sent!");

        // Reset form fields
        setFormData({ name: "", email: "", message: "" });

        // Send the automatic reply
        const replyParams = {
          to_name: formData.name,
          to_email: formData.email,
        };

        emailjs
          .send(
            emailJsID.SERVICE_ID,
            emailJsID.autoReplyTemplateID,
            replyParams,
            emailJsID.USER_ID
          )
          .then((response) => {
            console.log(
              "Auto-reply sent successfully!",
              response.status,
              response.text
            );
          })
          .catch((error) => {
            console.error("Auto-reply sending failed:", error);
          });

        setFormStatus({ submitted: true, error: false, success: true });
      })
      .catch((error) => {
        console.error("There was an error sending the email:", error);
        alert("Failed to send message.");
        setFormStatus({ submitted: true, error: true, success: false });
      });
  };

  return (
    <>
      <div className="contact-container">
        <div className="formDiv">
          <h1>Contact Us</h1>
          <p>
            If you have any questions, feel free to reach out to us by filling
            out the form below.
          </p>

          {formStatus.submitted && (
            <div
              className={`alert ${
                formStatus.error ? "alert-danger" : "alert-success"
              }`}
            >
              {formStatus.error
                ? "There was an error submitting the form."
                : "Thank you for your message! We will get back to you soon."}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button type="submit" className="btn btn-primary">
              Send
            </button>
          </form>
        </div>
      </div>
      <div className="contact-info">
        <h2>Our Contact Information</h2>
        <p>
          <strong>Phone:</strong> (+971) 5858-25996 
        </p>
        <p>
          <strong>Email:</strong> info@ohmycar.org
        </p>
        <p>
          <strong>Address:</strong> Jaddaf Waterfront, Dubai, UAE
        </p>
      </div>

      <div className="map">
        <h2>Our Location</h2>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7218.64259081822!2d55.332931245364!3d25.22610111076921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d684bd88689%3A0xe0d7a44e8e28701!2sAl%20Jaddaf%20-%20Jaddaf%20Waterfront%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sde!4v1720224466493!5m2!1sen!2sde"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Company Location"
        ></iframe>
      </div>

      <div className="social-media">
        <h2>Connect with Us</h2>
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        <a
          href="https://linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </div>
    </>
  );
}

export default Contact;
