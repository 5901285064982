import toyotaAvalon from "../assets/cars_photo/toyota-avalon-2021.png";

import kiaSeltos from "../assets/cars_photo/kia_seltos_2021.png";
import kiaForte from "../assets/cars_photo/kia_forte_2021.png";
import kiaSportage from "../assets/cars_photo/kia sportage 2023.png";
import kiaRio from "../assets/cars_photo/kia_2020 rio.png";

import hyundaiSantaFe from "../assets/cars_photo/Hyundai_SantaFe_2023.png";
import hyundaiElantra from "../assets/cars_photo/hyundai elantra 2021.png";
import sonata from "../assets/cars_photo/2021-Sonata-2021.png";

import audiA4 from "../assets/cars_photo/audi_a4_2021png.png";

import challenger from "../assets/cars_photo/2022-Dodge-Challenger.png";

import tributo from "../assets/cars_photo/Ferrari-F8-Tributo-2020-6.png";
import Spider from "../assets/cars_photo/Ferrari F8 Spider.png";
import GTB from "../assets/cars_photo/Ferrari-488-GTB.png";
import spiderBlack from "../assets/cars_photo/ ferrari_488_spider_black.png";

import evoSpider from "../assets/cars_photo/Lamborghini Evo Spider .png";
import urus from "../assets/cars_photo/2020-Lamborghini-Urus-white.png";
import urusPurple from "../assets/cars_photo/urus_purple.png";
import urusGreen from "../assets/cars_photo/urus-green.png";
import urusYellow from "../assets/cars_photo/urus-yellow.png";
import urusBlack from "../assets/cars_photo/urus-black.png";

import huracan from "../assets/cars_photo/Lamborghini-Huracan-STO.png";
import huracanRed from "../assets/cars_photo/huracan-red.png";
import huracanYellow from "../assets/cars_photo/huracan-yellow.png";
import huracanBlue from "../assets/cars_photo/huracanBlue.png";
import huracanWhite from "../assets/cars_photo/Huracan-Spyder-white.png";

import cullinan from "../assets/cars_photo/Rolls_Royce_Cullinan_2022.png";
import cullinanWhite from "../assets/cars_photo/rolls-royce-cullinan.png";

import WraithWhite from "../assets/cars_photo/white-rolls-royce-wraith-side-view-araugzr1ksqg214x.png";

import DawnRed from "../assets/cars_photo/2021-rolls-royce-dawn.png";
import DawnWhite from "../assets/cars_photo/Rolls-Royce-Dawn-white.png";
import DawnBlack from "../assets/cars_photo/Rolls-Royce-Dawn-Black.png";

import SvrBlack from "../assets/cars_photo/range-rover-SVR-Black.png";
import SvrBlue from "../assets/cars_photo/Range-Rover-SVR-Blue.png";
import VogueBlack from "../assets/cars_photo/Range-Rover-Vogue-Black.png";

import GLE63sBlack from "../assets/cars_photo/mercedes-gle63s-black.png";
import G63sBlackMatte from "../assets/cars_photo/mercedes-g-63-matte-black.png";
import GT63s from "../assets/cars_photo/mercedes-gt63s.png";
import ECabriolet from "../assets/cars_photo/Mercedes-E400-cabriolet .png";
import GBrabus from "../assets/cars_photo/Mercedes-G63-Brabus .png";
import GBlack from "../assets/cars_photo/MbG63Black.png";
import GWhite from "../assets/cars_photo/MbG63White.png";
import GGreen from "../assets/cars_photo/MbG63Green.png";
import C200 from "../assets/cars_photo/Mercedes-Benz-C200-White.png";
import S500 from "../assets/cars_photo/Mercedes-S500.png";
import VIP from "../assets/cars_photo/mercedes-v-class-vip .png";
import A35 from "../assets/cars_photo/MB-A35-AMG.png";

import Bentayga from "../assets/cars_photo/bentley bentayga.png";

export const carsData = [
  {
    id: 1,
    image: toyotaAvalon,
    companyName: "Toyota ",
    name: "Avalon",
    type: "sedan",
    rent: {
      daily: 300,
      weekly: 1800,
      monthly: 4800,
    },
    capacity: 5,
  },
  {
    id: 2,

    image: sonata,
    companyName: "Hyundai ",
    name: "Sonata",
    type: "sedan",
    rent: {
      daily: 140,
      weekly: 800,
      monthly: 2800,
    },
    capacity: 5,
  },
  {
    id: 3,
    image: kiaForte,
    companyName: "Kia ",
    name: "Forte",
    type: "sedan",
    rent: {
      daily: 100,
      weekly: 600,
      monthly: 220,
    },
    capacity: 5,
  },
  {
    id: 4,

    image: challenger,
    companyName: "Dodge ",
    name: "Challenger",
    type: "sport",
    rent: {
      daily: 400,
      weekly: 2400,
      monthly: 7000,
    },
    capacity: 5,
  },
  {
    id: 5,
    image: hyundaiElantra,
    companyName: "Hyundai ",
    name: "Elantra",
    type: "sedan",
    rent: {
      daily: 120,
      weekly: 700,
      monthly: 2400,
    },
    capacity: 5,
  },
  {
    id: 6,
    image: audiA4,
    companyName: "Audi ",
    name: "A4",
    type: "sedan",
    rent: {
      daily: 350,
      weekly: 2100,
      monthly: 6500,
    },
    capacity: 5,
  },
  {
    id: 7,

    image: kiaSportage,
    companyName: "Kia ",
    name: "Sportage",
    type: "suv",
    rent: {
      daily: 250,
      weekly: 1400,
      monthly: 3800,
    },
    capacity: 5,
  },
  {
    id: 8,

    image: kiaSeltos,
    companyName: "Kia ",
    name: "Seltos",
    type: "suv",
    rent: {
      daily: 130,
      weekly: 750,
      monthly: 2500,
    },
    capacity: 5,
  },
  {
    id: 9,
    image: hyundaiSantaFe,
    companyName: "Hyundai ",
    name: "SantaFe",
    type: "suv",
    rent: {
      daily: 220,
      weekly: 1300,
      monthly: 3500,
    },
    capacity: 5,
  },
  {
    id: 10,
    image: kiaRio,
    companyName: "Kia ",
    name: "Rio",
    type: "sedan",
    rent: {
      daily: 100,
      weekly: 600,
      monthly: 2000,
    },
    capacity: 5,
  },
  {
    id: 11,
    image: tributo,
    companyName: "Ferrari ",
    name: "Tributo F8",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 12,
    image: Spider,
    companyName: "Ferrari ",
    name: "Spider F8",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 13,
    image: GTB,
    companyName: "Ferrari ",
    name: "488 GTB",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 14,
    image: spiderBlack,
    companyName: "Ferrari ",
    name: "488 Spider",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 15,
    image: huracan,
    companyName: "Lamborghini ",
    name: "Huracan STO",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 16,
    image: evoSpider,
    companyName: "Lamborghini ",
    name: "Evo Spider",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 17,
    image: urus,
    companyName: "Lamborghini ",
    name: "Urus",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  //
  {
    id: 18,
    image: urusPurple,
    companyName: "Lamborghini ",
    name: "Urus",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 19,
    image: huracanRed,
    companyName: "Lamborghini ",
    name: "Huracan",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 20,
    image: huracanYellow,
    companyName: "Lamborghini ",
    name: "Huracan",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 21,
    image: huracanBlue,
    companyName: "Lamborghini ",
    name: "Huracan Spider",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 2,
  },
  {
    id: 22,
    image: huracanWhite,
    companyName: "Lamborghini ",
    name: "Huracan Spider ",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 23,
    image: urusGreen,
    companyName: "Lamborghini ",
    name: "Urus",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 24,
    image: urusYellow,
    companyName: "Lamborghini ",
    name: "Urus",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 25,
    image: urusBlack,
    companyName: "Lamborghini ",
    name: "Urus",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  //
  {
    id: 26,
    image: cullinan,
    companyName: "Rolls Royce ",
    name: "Cullinan",
    type: "luxury",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 27,
    image: cullinanWhite,
    companyName: "Rolls Royce ",
    name: "Cullinan",
    type: "luxury",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 28,
    image: WraithWhite,
    companyName: "Rolls Royce ",
    name: "Wraith",
    type: "luxury",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 29,
    image: DawnRed,
    companyName: "Rolls Royce ",
    name: "Dawn",
    type: "luxury",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 4,
  },
  {
    id: 30,
    image: DawnWhite,
    companyName: "Rolls Royce ",
    name: "Dawn",
    type: "luxury",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 4,
  },
  {
    id: 31,
    image: DawnBlack,
    companyName: "Rolls Royce ",
    name: "Dawn",
    type: "luxury",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 4,
  },
  {
    id: 32,
    image: SvrBlack,
    companyName: "Range Rover ",
    name: "SVR",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 33,
    image: SvrBlue,
    companyName: "Range Rover ",
    name: "SVR",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 34,
    image: VogueBlack,
    companyName: "Range Rover ",
    name: "Vogue",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 35,
    image: GLE63sBlack,
    companyName: "Mercedes Benz ",
    name: "Gle 63 S",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 36,
    image: GT63s,
    companyName: "Mercedes Benz ",
    name: "GT 63 S",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 37,
    image: ECabriolet,
    companyName: "Mercedes Benz ",
    name: "E 400",
    type: "sport",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 4,
  },
  {
    id: 38,
    image: G63sBlackMatte,
    companyName: "Mercedes Benz ",
    name: "G 63 Matte",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 39,
    image: GBrabus,
    companyName: "Mercedes Benz ",
    name: "G 63 Brabus",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 40,
    image: GBlack,
    companyName: "Mercedes Benz ",
    name: "G 63",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 41,
    image: GWhite,
    companyName: "Mercedes Benz ",
    name: "G 63",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 42,
    image: GGreen,
    companyName: "Mercedes Benz ",
    name: "G 63",
    type: "suv",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 43,
    image: C200,
    companyName: "Mercedes Benz ",
    name: "C 200",
    type: "sedan",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 44,
    image: S500,
    companyName: "Mercedes Benz ",
    name: "S 500",
    type: "sedan",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 45,
    image: VIP,
    companyName: "Mercedes Benz ",
    name: "V Class VIP",
    type: "luxury",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 7,
  },
  {
    id: 46,
    image: A35,
    companyName: "Mercedes Benz ",
    name: "A 35",
    type: "Hatch",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
  {
    id: 47,
    image: Bentayga,
    companyName: "Bentley ",
    name: "Bentayga",
    type: "luxury",
    rent: {
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    capacity: 5,
  },
];
