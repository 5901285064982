import React from "react";
import CarList from "./CarsList";
function LandingPage() {
  return (
    <div className="landingPage">
      <div className="hero"></div>
      <CarList />
    </div>
  );
}

export default LandingPage;
