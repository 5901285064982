import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/cars_photo/Logo_1.png";

function Navbar() {


  return (
    <div className="navContainer">
      <nav className=" navbar">
        <NavLink to="/" className="navLinkLogo" activeclassname="active">
          <img src={logo} alt="logo" width="100px" />
        </NavLink>
        <NavLink to="/contact" className="navLink " activeclassname="active">
          CONTACT
        </NavLink>
        <NavLink to="/about" className="navLink" activeclassname="active">
          ABOUT
        </NavLink>
       
       
      </nav>
    </div>
  );
}

export default Navbar;
