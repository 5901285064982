import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "./Context";

function ContextContainer({ children }) {
  // const [companyLoginData, setCompanyLoginData] = useState({});
  // const [isAdminLogin, setIsAdminLogin] = useState(false);
  // const [isFreelancerLogin, setIsFreelancerLogin] = useState(false);
  // const [isTitleFilter, setIsTitleFilter] = useState(false);
  // const [inputValue, setInputValue] = useState([]);
  // const [freelancerLoginData, setFreelancerLoginData] = useState({});
  // const [freelancerFind, setFreelancerFind] = useState({});
  // const [oneCompanyJob, setOneCompanyJob] = useState({});
  // const [jobAccepted, setJobAccepted] = useState({});
  const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   // console.log(token);
  //   fetch("http://localhost:4000/graphql", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // token: localStorage.getItem("token"),
  //       Authorization: token ? `Bearer ${token}` : "",
  //     },
  //     body: JSON.stringify({
  //       query: `query{
  //         authPayload {
            
  //           user {
  //             id
  //             username
  //             isAdmin
  //           }
  //         }
            
  //       }
      
  //       `,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.data.authPayload?.user.isAdmin) {
  //         console.log(result, "success");
  //         console.log(result.data.authPayload.user.isAdmin);
  //         setIsAdminLogin(true);
  //       } else {
  //         navigate(1);
  //         console.log("error");
  //       }
  //     });
  // }, []);

  return (
    <MyContext.Provider
      value={{
        // isAdminLogin,
        // setIsAdminLogin,
        // isFreelancerLogin,
        // setIsFreelancerLogin,
        // isTitleFilter,
        // setIsTitleFilter,
        // inputValue,
        // setInputValue,
        // setFreelancerLoginData,
        // freelancerLoginData,
        // freelancerFind,
        // setFreelancerFind,
        // oneCompanyJob,
        // setOneCompanyJob,
        // jobAccepted,
        // setJobAccepted,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

export default ContextContainer;
