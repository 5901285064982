import React from "react";
// import Brabus from "../assets/Hero_with_Brabus_wh_bg.png";
function About() {
  return (
    <div className="aboutContainer">
    <div className="about-us">
      
      <div className="imgSection">
        <h1>About Oh My Car!</h1>
        <p>
          Welcome to <strong>Oh My Car!</strong>, Dubai's premier car rental
          service, where customer satisfaction and high-quality service meet
          exceptional value. Established in 2022, we have proudly served the
          residents and visitors of Dubai for over six years, delivering
          top-notch car rental experiences that cater to a variety of needs and
          preferences.
        </p>
          {/* <img className="brabus" src={Brabus} alt="brabus" /> */}
          <div className="brabus"></div>
      </div>
      <div className="contentSection">
        <h2>Our Journey</h2>
        <p>
          Since our inception, Oh My Car! has been dedicated to providing
          reliable and affordable car rental solutions. Our commitment to
          excellence and customer-centric approach has allowed us to grow and
          thrive in a competitive market. Over the years, we have built a
          reputation for:
        </p>
        <ul>
          <li>
            <strong>Diverse Fleet:</strong> Our extensive fleet includes a wide
            range of vehicles from compact cars and sedans to luxury SUVs and
            sports cars, ensuring that you find the perfect ride for any
            occasion.
          </li>
          <li>
            <strong>Exceptional Service:</strong> Our team of experienced
            professionals is always ready to assist you, providing personalized
            service to make your rental experience smooth and enjoyable.
          </li>
          <li>
            <strong>Competitive Pricing:</strong> We offer transparent and
            competitive pricing, ensuring you get the best value for your money
            without compromising on quality.
          </li>
        </ul>
        <h2>Why Choose Us?</h2>
        <p>
          At Oh My Car!, we understand that every customer has unique needs.
          That's why we strive to offer flexible rental options, whether you
          need a car for a day, a week, or an extended period. Our easy booking
          process, combined with our commitment to customer satisfaction, makes
          renting a car with us a hassle-free experience.
        </p>
        <h2>Our Mission</h2>
        <p>
          Our mission is simple: to provide the best car rental service in
          Dubai. We aim to exceed your expectations by offering:
        </p>
        <ul>
          <li>
            <strong>Quality Vehicles:</strong> All our cars are well-maintained
            and undergo regular inspections to ensure safety and reliability.
          </li>
          <li>
            <strong>Customer Support:</strong> Our friendly customer support
            team is available around the clock to assist you with any queries or
            concerns.
          </li>
          <li>
            <strong>Convenience:</strong> With multiple pick-up and drop-off
            locations across Dubai, renting a car has never been more
            convenient.
          </li>
        </ul>
        <h2>Join the Oh My Car! Family</h2>
        <p>
          We invite you to join the thousands of satisfied customers who have
          chosen Oh My Car! for their rental needs. Whether you're a resident
          looking for a temporary vehicle or a tourist exploring the vibrant
          city of Dubai, we have the perfect car for you.
        </p>
        <p>
          Thank you for choosing Oh My Car! We look forward to serving you and
          making your driving experience in Dubai unforgettable.
        </p>
      </div>
    </div></div>
  );
}

export default About;
