import React, { useState, useEffect } from "react";
import carToTop from "../assets/car-to-top.png";
const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Toggle visibility of the button
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "0px",
            right: "0px",
            //     background: "#000",
            //         color: "#fff",
            border: "none",
            // padding: "10px",
            borderRadius: "5px",
            cursor: "pointer",
            zIndex: 1000,
          }}
        >
          <img
            src={carToTop}
            alt="top"
            width="40px"
            style={{ backgroundColor: "white" }}
          />
        </button>
      )}
    </div>
  );
};

export default GoToTop;
