import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./styles/App.scss";
import LandingPage from "./components/LandingPage";
import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
// import CarList from "./components/CarsList";
import Navbar from "./components/Navbar";
import ContextContainer from "./Context/ContextContainer";
import GoToTop from "./components/GoToTop";


function App() {
  return (
    <div className="App">

      <ContextContainer>
        <Navbar />

        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </ContextContainer>
      <GoToTop/>
    </div>
  );
}

export default App;
